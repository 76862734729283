.custom-image {
    width: 400px;
    height: 300px;
  }

  .centered-container {
  
    height: 30vh; 
  }

  .custom-image2 {
    width: 200px;
    height: 70px;
  }
